<template>
  <div class="space-y-4">
    <asom-alert v-if="error" variant="error" :error-message="error" />
    <article>
      <asom-card>
        <form class="grid sm:grid-cols-1 md:grid-cols-2 gap-4">
          <asom-form-field
            required
            label="Description"
            :state="inputStates('formData.description')"
            error="Description is required"
          >
            <asom-input-textarea
              type="text"
              disabled
              v-model="formData.description"
              :state="inputStates('formData.description')"
              :maxlength="1000"
            />
          </asom-form-field>
          <asom-form-field label="Occurrence Date & Time">
            <asom-input-date-time v-model="formData.occurrenceDate" />
          </asom-form-field>
          <div>
            <asom-form-field
              :label="'Is Expiry Date Required'"
              :state="inputStates('formData.isExpiryDateRequired')"
              error="Please check"
            >
              <asom-input-radio-group
                class="mt-3"
                horizontal
                v-model="formData.isExpiryDateRequired"
                :options="expireDateOptions"
                :state="inputStates('formData.isExpiryDateRequired')"
              />
            </asom-form-field>
            <asom-form-field
              v-if="formData.isExpiryDateRequired == 'true'"
              label="Expiry Date"
              required
              :state="inputStates('formData.expiryDate')"
              error="Expiry Date is required"
            >
              <asom-input-date
                :min-date="new Date()"
                v-model="formData.expiryDate"
                :state="inputStates('formData.expiryDate')"
              />
            </asom-form-field>
          </div>
          <asom-form-field
            :label="'Item Status'"
            :state="inputStates('formData.isItemOpened')"
            error="Please select"
          >
            <asom-input-radio-group
              class="mt-3"
              horizontal
              v-model="formData.isItemOpened"
              :options="itemStatusOptions"
              :state="inputStates('formData.isItemOpened')"
            />
          </asom-form-field>
          <asom-form-field
            required
            label="Remarks"
            :state="inputStates('formData.remarks')"
            error="Remarks is required"
          >
            <asom-input-textarea
              type="text"
              v-model="formData.remarks"
              :state="inputStates('formData.remarks')"
              :maxlength="1000"
            />
          </asom-form-field>
          <asom-form-field
            class="col-span-2"
            label="Supporting documents"
            :state="inputStates('formData.files')"
            error="Supporting documents are required"
          >
            <asom-upload
              v-model="formData.files"
              :category="attachmentCategories.FAULT_MANAGEMENT"
              :state="inputStates('formData.files')"
            />
            <asom-upload-file-list
              :files="formData.uploadedFiles"
              disabled-remove
            />
          </asom-form-field>
          <div class="col-span-2 flex justify-end space-x-4 pt-8">
            <asom-button
              text="Back"
              variant="secondary"
              @click="$router.go(-1)"
            />
            <asom-button
              text="Update"
              @click="updateFaultOccurence"
              :loading="isSubmitting"
            />
          </div>
        </form>
      </asom-card>
    </article>
  </div>
</template>

<script>
import get from "lodash.get";
import { parseDateTime } from "@/helpers/dateTimeHelpers";
import { mapGetters } from "vuex";
import { required } from "@vuelidate/validators";
import inputStates from "@/mixins/inputStates";
import { useVuelidate } from "@vuelidate/core";
import {
  editFaultOccurrence,
  getFaultOccurrenceDetails,
} from "../../../services/faultManagement.service";
import { ATTACHMENT_CATEGORIES } from "@/constants/APIEnums/attachmentEnums";

export default {
  setup: () => ({ v$: useVuelidate() }),
  mixins: [inputStates],
  data() {
    return {
      error: null,
      isSubmitting: false,
      formData: {
        description: null,
        expiryDate: null,
        isItemOpened: "false",
        isExpiryDateRequired: "false",
        files: [],
        uploadedFiles: [],
        remarks: null,
        occurrenceDate: null,
      },
    };
  },
  validations() {
    let validations = {
      formData: {
        isItemOpened: { required },
        isExpiryDateRequired: { required },
        remarks: { required },
      },
    };
    if (this.formData.isExpiryDateRequired == true) {
      validations.formData.expiryDate = { required };
    }
    return validations;
  },
  computed: {
    ...mapGetters({
      stationId: "selectedStation/id",
    }),
    itemStatusOptions() {
      return [
        { value: "true", label: "Open" },
        { value: "false", label: "Closed" },
      ];
    },
    expireDateOptions() {
      return [
        { value: "true", label: "Yes" },
        { value: "false", label: "No" },
      ];
    },
    attachmentCategories() {
      return ATTACHMENT_CATEGORIES;
    },
  },
  mounted() {
    this.getDetails();
  },
  methods: {
    async getDetails() {
      const result = await getFaultOccurrenceDetails({
        faultOccurrenceId: get(this.$route.params, "occurenceId"),
        stationId: this.stationId,
      });
      if (result.success) {
        this.setFormData(get(result.payload, "model"));
      }
    },
    setFormData(data) {
      this.formData.description = get(data, "description", null);
      this.formData.expiryDate = get(data, "expiryDate", null);
      this.formData.occurrenceDate = get(data, "occurrenceDate", null);
      this.formData.isItemOpened = get(data, "isItemOpened", false).toString();
      this.formData.isExpiryDateRequired = get(
        data,
        "isExpiryDateRequired",
        false
      ).toString();
      let attachments = get(data, "attachments", []);
      this.formData.uploadedFiles = attachments.map((attachment) => {
        attachment.fileId = attachment.attachmentId;
        return attachment;
      });
    },
    updateFaultOccurence() {
      if (this.isSubmitting) return;
      this.error = "";
      this.v$.$reset();
      this.v$.$touch();
      if (!this.v$.$invalid) {
        this.onSubmit();
      } else {
        this.error = "Please complete all required fields";
        this.$scrollTop();
      }
    },
    async onSubmit() {
      let uploadedIds = [];
      if (this.formData.files) {
        uploadedIds = this.formData.files.map((file) => file.fileId);
      }
      if (this.formData.uploadedFiles) {
        uploadedIds.concat(
          this.formData.uploadedFiles.map((file) => file.fileId)
        );
      }
      this.isSubmitting = true;
      const result = await editFaultOccurrence({
        faultOccurrenceId: get(this.$route.params, "occurenceId"),
        isItemOpened: this.formData.isItemOpened,
        expiryDate: parseDateTime(this.formData.expiryDate, false),
        attachments: uploadedIds,
        remarks: this.formData.remarks,
        description: this.formData.description,
        isExpiryDateRequired: this.formData.isExpiryDateRequired,
        occurrenceDate: parseDateTime(this.formData.occurrenceDate, false),
      });
      if (result.success) {
        this.isSubmitting = false;
        this.$router.go(-1);
      } else {
        this.isSubmitting = false;
        this.error = result.payload;
        this.$scrollTop();
      }
    },
  },
};
</script>
